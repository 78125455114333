import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Nested = ({ data, closeNavbar }) => {
    const [open, setOpen] = useState(null);

    const toggleDropdown = (index, e) => {
        if (e.child && e.child.length > 0) {
            setOpen((prev) => (prev === index ? null : index));
        } else {
            setOpen(null);
            closeNavbar()
        }
    };

    const handleLinkClick = () => {
        closeNavbar();
    };

    const closedropdonw = () => {
        console.log(data)
        closeNavbar()
        setOpen(null)
    }

    console.log("openechek", open);

    return (
        <>
            {data.map((e, i) => (
                <div key={i} className="nav-item">
                    <div className="nav-link" onClick={() => { toggleDropdown(i, e) }}>
                        {e.path ? (
                            <Link to={e.path} onClick={handleLinkClick}>{e.text}</Link>
                        ) : (
                            <p>{e.text}</p>
                        )}
                        {e.child ? <span className="dropdown-arrow">&darr;</span> : ""}
                    </div>
                    {e.child && i === open ? (
                        <div className="dropdown-menu" style={{ paddingLeft: '10px' }}>
                            <Nested data={e.child} closeNavbar={closedropdonw } />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ))}
        </>
    );
};

export default Nested;