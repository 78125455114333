import React from 'react';
import Logo from "../assets/Mahapatra-logo.png"

const Footer = () => {
  return (
    <>
      <div className="flex flex-col justify-center px-16 py-2.5 bg-white bg-opacity-60 max-md:px-5 mt-5">
        <div className="flex gap-5 justify-between py-2.5 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
          <div className="flex flex-col justify-center my-auto uppercase max-md:max-w-full">
            <div className="flex flex-col justify-center px-1.5 max-md:max-w-full">
              <div className="flex flex-col justify-center px-9 py-2 max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col max-md:max-w-full">
                  <div className="flex gap-1 text-5xl font-bold tracking-tighter text-red-700 whitespace-nowrap max-md:flex-wrap">
                    <img src={Logo} alt="" srcset="" style={{width: "300px"}}/>
                   </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-5 justify-between px-0.5 py-4">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b275df259048ee2c8b64df55af203e2e4d5f0335b86904e2f5bb116d96334d41?apiKey=4381ccf4e3da4ae69491c30e1153ffec&"
              className="shrink-0 aspect-[0.93] w-[70px]"
            />
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a572f7c01cc70f0bda9fc26afe0093725f354c174f1476ce7a9c2d92939c19?apiKey=4381ccf4e3da4ae69491c30e1153ffec&"
              className="shrink-0 my-auto aspect-[1.04] w-[70px]"
            />
          </div>
        </div>
        <div className="flex gap-5 justify-between self-end mt-2.5 max-w-full text-sm font-bold leading-6 w-[696px] max-md:flex-wrap">
          <div className="flex flex-col justify-center text-neutral-700">
            <div className="justify-center">©MAHAPATRA UNIVERSAL LTD 2024</div>
          </div>
          <div className="text-red-700 underline">
            <span className="font-medium">Powered by </span>
            <a
              href="https://Sceniuz.com"
              className="font-medium text-red-700 underline"
              target="_blank"
            >
              Sceniuz
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
